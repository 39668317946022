import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCS0dGd0mKy3JYEAuE5X23tgOcYLLJEjVY",
  authDomain: "hackathon-47ad0.firebaseapp.com",
  projectId: "hackathon-47ad0",
  storageBucket: "hackathon-47ad0.appspot.com",
  messagingSenderId: "744752772676",
  appId: "1:744752772676:web:51118b682bc703f7492e11",
  measurementId: "G-2V4JPMBM3N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

ReactDOM.render(<App />, document.getElementById('root'));
